<!--运行维护-SIM卡号-->
<template>
  <main>
    <!-- 工具条 -->
    <el-row>
      <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
        <el-form :inline="true" :model="filter" size="mini">
          <el-form-item>
            <el-cascader
              v-model="filter.param.Owner"
              @change="getData"
              placeholder="所属单位"
              :options="customerTree"
              :props="orgProps"
              clearable
              filterable
            />
          </el-form-item>
          <el-form-item>
            <el-select
              placeholder="提交人"
              v-model="filter.param.handler"
              @change="getData"
              clearable
              filterable
            >
              <el-option
                v-for="item in userList"
                :key="item.Id"
                :label="item.Name"
                :value="item.Name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择日期">
            <el-date-picker
              type="daterange"
              v-model="filter.param.audit_at"
              @change="getData"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="handleShowDialog('ADD')"
            >新增</el-button
            >
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <el-row>
      <el-col :span="24">
        <el-table
          :data="datalist.content"
          size="mini"
          :max-height="clientHeight"
          border
          highlight-current-row
          v-loading="loading"
          style="width: 100%"
        >
          <el-table-column type="index" label="#" align="center" header-align="center" width="55" />
          <el-table-column
            prop="MN"
            label="设备编码"
            show-overflow-tooltip
            align="center"
            header-align="center"
          />
          <el-table-column
            prop="Owner"
            label="所属单位"
            show-overflow-tooltip
            :formatter="customerFormatter"
            align="center"
            header-align="center"
          />
          <el-table-column
            prop="Addr"
            label="安装地址"
            show-overflow-tooltip
            align="center"
            header-align="center"
            width="160"
          />
          <el-table-column
            prop="Subtype"
            label="设备类型"
            align="center"
            header-align="center"
            :formatter="subTypeFormatter"
            width="160"
          />
          <el-table-column
            prop="Sim"
            label="SIM卡号"
            align="center"
            header-align="center"
          />
          <el-table-column
            prop="Handler"
            label="提交人"
            align="center"
            header-align="center"
          />
          <el-table-column
            prop="AuditAt"
            label="提交日期"
            align="center"
            header-align="center"
            :formatter="dateFormat"
          />
          <el-table-column
            label="操作"
            align="center"
            header-align="center"
            width="210"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="handleShowDialog('EDIT', scope.row)"
              >修改</el-button>
              <el-button
                type="text"
                @click="handleShowDialog('DELETE', scope.row)"
              >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- 分页 -->
    <el-row>
      <el-col :span="24" class="toolbar">
        <el-pagination
          small
          background
          @size-change="(v) => handlePageChange(v, 'size')"
          @current-change="(v) => handlePageChange(v, 'page')"
          :current-page.sync="filter.page"
          :page-sizes="filter.pageSizes"
          layout="total, sizes, prev, pager, next, jumper"
          :total="datalist.total"
          style="display: inline-block; margin-left: 15px"
        >
        </el-pagination>
      </el-col>
    </el-row>
    <!--编辑/新增界面-->
    <el-dialog
      :title="dlg.title"
      :visible.sync="dlg.visible"
      width="25%"
      @close="handleClose"
    >
      <el-form
        size="mini"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
        :model="formData"
        :disabled="dlg.title === '查看'"
      >
        <el-form-item label="设备编码" prop="MN" :error="errorMsg">
          <el-input :disabled="dlg.title === '修改'" v-model="formData.MN" placeholder="请先填写MN" @change="getDeviceDetail"></el-input>
        </el-form-item>
        <el-form-item label="所属单位">
          <el-input disabled type="textarea" :placeholder="getPname(this.customerTree, formData.Owner, 'Org')"></el-input>
        </el-form-item>
        <el-form-item label="安装地址">
          <el-input disabled :placeholder="formData.Addr"></el-input>
        </el-form-item>
        <el-form-item label="设备类型">
          <el-input disabled :placeholder="referMap['d' + formData.Subtype]"></el-input>
        </el-form-item>
        <el-form-item label="SIM卡号" prop="Sim">
          <el-input v-model="formData.Sim" placeholder="请先填写MN"></el-input>
        </el-form-item>
        <el-button style="float:right;margin-left:10px;" size="mini" type="primary" @click="handleSubmit()">提交</el-button>
        <el-button style="float:right" size="mini" type="primary" @click="dlg.visible = false">取消</el-button>
      </el-form>
    </el-dialog>
  </main>
</template>

<script>
  import { mapState } from 'vuex'
  import {
    join,
    getPname,
    referToMap
  } from '@/util/index'

  export default {
    name: 'Sim',
    data() {
      return {
        loading: false,
        dialogLoading: false,
        filter: {
          page: 1,
          size: 20,
          pageSize: [20, 30, 50],
          param: {
            handler: '',
            Owner: [],
            audit_at: null
          }
        },
        dlg: {
          title: '',
          visible: false
        },
        datalist: {
          content: [],
          total: null
        },
        formData: {
          MN: '',
          Owner: '',
          Addr: '',
          Subtype: null,
          Sim: ''
        },
        rules: {
          MN: [
            { required: true, message: '请输入设备编码', trigger: 'blur' }
          ],
          Sim: [
            { required: true, message: '请输入SIM卡号', trigger: 'blur' }
          ]
        },
        referMap: {},
        errorMsg: '',
        userList: []
      }
    },
    created() {
      this.getData()
      this.$get('admin/listCascadeReferParam', { pcode: 'DEVICE_TYPE', code: 'DEVICE_SUBTYPE', c_code: 'DEVICE_MODEL' }).then(res => {
        this.referMap = referToMap(res, 'Id', 'Name')
        res.forEach(e => {
          if (e.Pid !== '') {
            e.Pid = '/' + e.Pid + '/'
          }
        })
      })
      this.$get('admin/listSubmitter').then((res) => {
        this.userList = res.content.slice()
      })
    },
    computed: {
      ...mapState({
        orgProps: (state) =>
          Object.assign({}, state.props, { label: 'Org', checkStrictly: true })
      }),
      ...mapState([
        'clientHeight',
        'customerTree'
      ])
    },
    methods: {
      getPname,
      subTypeFormatter(r, c) {
        return this.referMap['d' + r[c.property]]
      },
      customerFormatter(r, c, v) {
        return getPname(this.customerTree, v, 'Org')
      },
      handleSubmit() {
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            const payload = Object.assign({}, this.formData)
            this.$post('admin/saveSimRecord', payload).then((res) => {
              this.dlg.visible = false
            }).catch(e => {
              this.errorMsg = e.Msg
            })
          } else {
            return false
          }
        })
      },
      handleShowDialog(type, row) {
        switch (type) {
          case 'EDIT':
            this.dlg.title = '修改'
            this.formData = Object.assign({}, row)
            break
          case 'DELETE':
            this.$confirm('确认删除该条信息吗？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              const payload = {
                id: row.Id
              }
              this.$get('admin/delectRecordOfDevice', payload).then(() => {
                this.handleClose()
              })
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消'
              })
            })
            return
          default:
            this.dlg.title = ''
        }
        this.dlg.visible = true
      },
      /**
       * @description dialog关闭事件
       */
      handleClose() {
        this.filter.page = 1
        this.dlg.title = ''
        this.formData = {
          MN: '',
          Owner: '',
          Addr: '',
          Subtype: '',
          Sim: ''
        }
        this.getData()
      },
      dateFormat(r, c) {
        return new Date(r[c.property]).toLocaleDateString('zh-CN').replaceAll('/', '-')
      },
      /**
       * @description 输入MN后回调，获取设备额外信息
       * @param val MN
       */
      // 010000A8900016F021060447
      getDeviceDetail(val) {
        const payload = {
          Param: {
            mn: {
              S: 4,
              V: val
            }
          }
        }
        this.errorMsg = '正在加载设备详情，加载后确认无误再填写SIM卡号'
        this.$post('admin/listDevice', payload).then((res) => {
          if (res.total !== 1) {
            this.errorMsg = 'MN有误，请重新输入'
            return
          }
          this.formData = {
            ...this.formData,
            Owner: res.content[0].Owner,
            Addr: res.content[0].Locale.Addr,
            Subtype: res.content[0].Subtype
          }
          this.errorMsg = ''
        }).catch(e => {
          this.errorMsg = e.Msg
        })
      },
      getData() {
        this.loading = true
        const payload = {
          StartAt: (this.filter.page - 1) * this.filter.size,
          Size: this.filter.size,
          Param: {}
        }
        if (this.filter.param.handler) {
          payload.Param['handler'] = { S: 0, V: `'${this.filter.param.handler}'` }
        }
        if (this.filter.param.Owner.length !== 0) {
          payload.Param['locale.owner'] = { S: 4, V: join(this.filter.param.Owner) }
        }
        if (this.filter.param.audit_at) {
          payload.Param['audit_at'] = { S: 11, V: `'${this.filter.param.audit_at[0]}', '${this.filter.param.audit_at[1]}'` }
        }
        this.$post('admin/listRecoedOfDevice', payload).then(res => {
          this.datalist = Object.assign({}, res)
          this.loading = false
        })
      },
      handlePageChange(val, field) {
        this.filter[field] = val
        this.getData()
      }
    }
  }
</script>

<style scoped>
</style>
